import React from "react";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink
} from "./NavbarElements";

function Navbar({ toggle }) {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">Anza Bio</NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          {/* <NavMenu>
            <NavItem>
              <NavLinks to="about">About</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="discover">Discovery</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="design">Design</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="biomanufacturing">Biomanufacturing</NavLinks>
            </NavItem>
          </NavMenu>
           <NavBtn>
            <NavBtnLink to="/contact">Contact</NavBtnLink>
          </NavBtn> */}
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default Navbar;
