import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./App.css";

import Home from "./pages";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} exact />
      </Routes>
    </div>
  );
}

export default App;
