export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lighttextDesc: true,
  /* topLine: "Transforming Medicine", */
  headline:
    "Accelerating Discovery & Sustainable Manufacturing of Therapeutics.",
  description:
    "Learning from 3 billion years of biology for a healthy and sustainable planet using augmented intelligence.",
  /* buttonLabel: "Learn More", */
  imgStart: false,
  img: require("../../assets/first.svg").default,

  alt: "Poster",
  dark: true,
  primary: true,
  darkText: false
};
