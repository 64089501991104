import React, { useState } from "react";
import Navbar from "../components/Navbar";

/* import HeroSection from "../components/HeroSection"; */
import InfoSection from "../components/InfoSection";
import { homeObjOne } from "../components/InfoSection/Data";

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Navbar toggle={toggle} />

      <InfoSection {...homeObjOne} />
    </>
  );
}

export default Home;
